<template>
  <v-container class="">
    <v-row v-show="info.length != 0">
      <v-col>
        <v-alert border="left" colored-border type="info" elevation="2">
          {{ info }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col align-self="center">
        <v-card elevation="10" class="mx-auto" max-width="500">
          <v-app-bar color="blue" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-account-details</v-icon>
              กรอกข้อมูล
            </v-toolbar-title>
          </v-app-bar>
          <v-container>
            <v-alert type="error" border="left" v-if="errorMessage != ''">
              {{ errorMessage }}
            </v-alert>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                maxlength="10"
                hint="รูปแบบการกรอกข้อมูล (0861234567)"
                label="เบอร์โทรศัพท์มือถือ"
                required
              >
                <template v-slot:append-outer>
                  <v-btn :disabled="!showRequestBtn" @click="requestPassword">
                    ขอรหัส
                  </v-btn>
                </template>
              </v-text-field>

              <v-text-field
                v-model="password"
                :disabled="!passwordRequested"
                :rules="otpRules"
                hint="รูปแบบการกรอกข้อมูล (538221)"
                label="รหัสยืนยัน"
                required
              ></v-text-field>

              <v-btn
                :disabled="!valid || !passwordRequested"
                color="success"
                class="mr-4 mt-5"
                @click="validate"
                :loading="loading"
                x-large
              >
                ตรวจสอบ
              </v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getSetting } from "@/utils/app.setting";
export default {
  data() {
    return {
      valid: false,
      showRequestBtn: true,
      passwordRequested: false,
      phone: "",
      phoneRules: [
        v => !!v || "จำเป็นต้องกรอกข้อมูล",
        v => /\d{10}/.test(v) || "เฉพาะตัวเลขไม่ต้องเว้นวรรคหรือใส่เครื่องหมาย"
      ],
      password: "",
      otpRules: [
        v => !!v || "กรอกข้อมูลรหัสที่ได้รับจากหมายเลขโทรศัพท์ที่คุณกรอกมา"
      ],
      loading: false,
      errorMessage: "",
      info: ""
    };
  },
  created() {
    this.getNotice();
  },
  watch: {
    phone(v) {
      if (/\d{10}/.test(v)) {
        this.showRequestBtn = true;
      } else {
        this.showRequestBtn = false;
      }
    }
  },
  methods: {
    validate() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        const url = process.env.VUE_APP_BASE_URL + "/api/client_validate";
        // const url = process.env.VUE_APP_BASE_URL + "/oauth/token";
        var config = {
          method: "post",
          url: url,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          data: {
            phone: this.phone,
            password: this.password
          }
        };
        this.axios(config)
          .then(res => {
            this.loading = false;
            // console.log(res.data);
            if (res.data.success) {
              this.$router.push({
                name: "AgentRegister",
                params: {
                  phone: this.phone,
                  token: res.data.access_token
                }
              });
            } else {
              this.errorMessage = res.data.message;
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    requestPassword() {
      if (/\d{10}/.test(this.phone)) {
        const url = process.env.VUE_APP_BASE_URL + "/api/request_password";
        var config = {
          method: "post",
          url: url,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          data: { phone: this.phone }
        };
        this.axios(config)
          .then(res => {
            // console.log(res.data);
            if (res.data.status == 1 || res.data.status == -1) {
              this.errorMessage = res.data.message;
            }
            if (res.data.success) {
              this.passwordRequested = true;
              this.showRequestBtn = false;
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    async getNotice() {
      let data = await getSetting("notice_confirmation_page");
      this.info = data;
    }
  }
};
</script>

<style></style>
